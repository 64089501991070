import BackgroundComponent from "../../components/BackgroundComponent";

function Home() {
  const propertyBackground = `bg-home `;
  const cssBackground = ` bg-no-repeat bg-cover lg:bg-contain lg:bg-right`;
  return (
    <main className="flex-grow relative ">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <section className="z-0 mt-[15%] text-center  lg:ml-[10%] items-right  lg:text-center lg:text-[#000000] flex px-4 lg:p-4 ">
        <div className="">
          <h2 className="lg:hidden text-2xl my-8">Home Page</h2>
          <p className="text-lg mb-5 px-5 max-w-[650px] text-center lg:text-black text-white">
            Bienvenue sur le Site de la compagnie de danse contamporaine Nana Moovement
          </p>
        </div>
      </section>
    </main>
  );
}

export default Home;
