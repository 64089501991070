import { useState, useEffect } from "react";
import BackgroundComponent from "../../components/BackgroundComponent";
import Box from "../../components/Box";
import image1 from "../../assets/NanaJamImages/10.webp";
import image2 from "../../assets/NanaJamImages/11.webp";
import image3 from "../../assets/NanaJamImages/12.webp";
import image4 from "../../assets/NanaJamImages/13.webp";
import image5 from "../../assets/NanaJamImages/14.webp";
import image6 from "../../assets/NanaJamImages/15.webp";

function NanaJam() {
  const [loaded, setLoaded] = useState(false);
  const propertyBackground = "bg-cremaster4";
  const cssBackground = `  lg:bg-cover  `;
  const tab = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
  ];
  const cssGallery = "lg:grid-cols-[1fr_1fr_2fr_1fr_3fr_1fr] gap-3 mt-4 grid-cols-[1fr]";

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <main className="flex-grow relative">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <section className="z-0 flex flex-row text-center items-start  max-w-[80%] mx-auto mt-16 flex-wrap mb-4 ">
        <div className="max-w-prose">
          <h2 className="lg:hidden text-2xl my-8">Nana Jam</h2>
          <p className="text-lg text-justify lg:px-5  m-auto">
            - EN CONSTRUCTION - Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis
            odit. At dolor labore id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae
            eligendi vel nesciunt galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde
            quo doloremque necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id
            voluptate rerum. Ut obcaecati ducimus sit maxime dolores et atque iure.
          </p>
          <p className="text-lg text-justify lg:px-5  m-auto">
            Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis odit. At dolor labore
            id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae eligendi vel nesciunt
            galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde quo doloremque
            necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id voluptate rerum.
            Ut obcaecati ducimus sit maxime dolores et atque iure.
          </p>
        </div>
        <div
          className={`w-[700px]  lg:ml-4 transition-opacity ease-in-out duration-[4000ms] ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="relative overflow-hidden w-full pt-[56.25%]">
            <iframe
              title="nanajamTeaser"
              className={`absolute top-0 left-0 bottom-0 right-0 w-full h-full `}
              id="ytplayer"
              type="text/html"
              src="https://www.youtube.com/embed/wUS3UsQIbWQ?autoplay=1&origin=http://example.com"
              allowFullscreen
            ></iframe>
          </div>
        </div>
        <Box tab={tab} cssGallery={cssGallery} />
      </section>
    </main>
  );
}

export default NanaJam;
