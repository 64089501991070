import BackgroundComponent from "../../../components/BackgroundComponent";
import { Link } from "react-router-dom";

function InSitu() {
  const propertyBackground = "bg-dr3";
  const cssBackground = ` bg-center  lg:!top-[100px] `;
  return (
    <main className="flex-grow relative ">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />

      <section className="items-center  flex flex-col  lg:px-4 lg:p-4  lg:mt-[15%] text-orange-50 max-w-[80%] m-auto mt-32">
        <h1 className="lg:my-8 text-2xl [text-shadow:1px_1px_2px_black] lg:[text-shadow:none]">
          Performances In Situ
        </h1>
        <p className="text-lg text-justify lg:w-[750px] [text-shadow:1px_1px_2px_black] lg:[text-shadow:none]">
          - EN CONSTRUCTION - Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis
          odit. At dolor labore id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae
          eligendi vel nesciunt galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde quo
          doloremque necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id
          voluptate rerum. Ut obcaecati ducimus sit maxime dolores et atque iure.
        </p>
      </section>
    </main>
  );
}

export default InSitu;
