import BackgroundComponent from "../../components/BackgroundComponent";

function Contact() {
  const PropertyBackground = "bg-cremaster1";

  const cssBackground = ` bg-[20%_40%] bg-[length:450px] lg:!top-[100px]`;
  return (
    <main className="flex-grow relative ">
      <BackgroundComponent PropertyBackground={PropertyBackground} cssBackground={cssBackground} />

      <section className="z-0 text-center lg:items-end lg:text-center lg:text-[#000000] flex lg:px-4 lg:p-4 mt-32 max-w-[80%] mx-auto flex-col lg:mr-[15%]">
        <h2 className="lg:hidden text-2xl my-8 bg-white">Contact</h2>
        <div className="   my-8 lg:max-w-96 ">
          <p className="text-lg  text-justify  ">
            <span className=" bg-white ">
              - EN CONSTRUCTION - Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis
              odit. At dolor labore id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae
              eligendi vel nesciunt galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde
              quo doloremque necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id
              voluptate rerum. Ut obcaecati ducimus sit maxime dolores et atque iure.
            </span>
          </p>
        </div>
      </section>
    </main>
  );
}

export default Contact;
