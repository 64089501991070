import { NavLink } from "react-router-dom";
import logo from "../../assets/logo_main.png";
import { useState, useEffect, useRef } from "react";

function Header() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    freezBody();
  });

  function freezBody() {
    if (isOpen === true) {
      ref.current.parentNode.parentNode.classList.add("overflow-hidden");
    } else {
      ref.current.parentNode.parentNode.classList.remove("overflow-hidden");
    }
  }
  return (
    <header ref={ref} className={` sticky lg:static top-0 z-10   lg:bg-white bg-transparent `}>
      <button
        onClick={() => setIsOpen(true)}
        className={`w-6 h-6 absolute ml-4 mt-4  ${
          isMobile
            ? `
                  z-50
                  ${isOpen ? "hidden" : ""}
                  `
            : "hidden"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className={`stroke-slate-700 
        `}
        >
          <path d="M21 9.5H3M21 4.5H3M21 14.5H3M21 19.5H3" />
        </svg>
      </button>

      <nav
        className={`${
          isMobile
            ? `
            ${isOpen ? "bg-white flex h-screen flex-col  " : "hidden"}`
            : "flex"
        } py-6 px-4 items-center lg:px-[50px] lg:flex-row lg:justify-between `}
      >
        <NavLink
          to="/"
          className="lg:ml-10 px-0 lg:px-[0.313rem] py-0 leading-snug hover:opacity-50 mx-auto"
          onClick={() => setIsOpen(false)}
        >
          <img src={logo} alt="logo_nana" className="max-w-[8rem]" />
        </NavLink>
        <div
          className={`lg:order-2 text-2xl lg:mr-10 lg:flex lg:flex-row flex-col flex flex-grow lg:justify-end justify-center `}
        >
          <NavLink
            to="/about"
            className="font-['Centrion'] lg:ml-16 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4"
            onClick={() => setIsOpen(false)}
          >
            À propos
          </NavLink>
          <NavLink
            to="/creations"
            className={`font-['Centrion'] lg:ml-8 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4`}
            onClick={() => setIsOpen(false)}
          >
            Créations
          </NavLink>
          <NavLink
            to="/nanajam"
            className="font-['Centrion'] lg:ml-8 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4"
            onClick={() => setIsOpen(false)}
          >
            Nana Jam
          </NavLink>
          <NavLink
            to="/ateliers"
            className="font-['Centrion'] lg:ml-8 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4"
            onClick={() => setIsOpen(false)}
          >
            Ateliers
          </NavLink>
          <NavLink
            to="/agenda"
            className="font-['Centrion'] lg:ml-8 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4"
            onClick={() => setIsOpen(false)}
          >
            Agenda
          </NavLink>
          <NavLink
            to="/contact"
            className="font-['Centrion'] lg:ml-8 lg:px-4 lg:mx-2 hover:opacity-50 whitespace-nowrap my-4"
            onClick={() => setIsOpen(false)}
          >
            Contact
          </NavLink>
        </div>
        <div className="lg:hidden text-base flex flex-row">
          <a
            href="https://www.facebook.com"
            className="px-4 mx-2  border-t-[0.2px] border-gray-500  whitespace-nowrap"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com"
            className=" mx-2 px-4 border-t-[0.2px] border-gray-500  whitespace-nowrap"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com"
            className="mx-2 px-4 border-t-[0.2px] border-gray-500  whitespace-nowrap"
          >
            Youtube
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
