import BackgroundComponent from "../../components/BackgroundComponent";

function Agenda() {
  const propertyBackground = ` bg-cremaster3 `;
  const cssBackground = `bg-cremaster3 bg-no-repeat lg:bg-contain lg:bg-right`;
  return (
    <main className="flex-grow relative">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <section className="text-center justify-end lg:items-start lg:text-center lg:text-[#000000] flex flex-col lg:w-[50%] max-w-[80%] mx-auto mt-32 ">
        <h2 className="lg:hidden text-2xl my-8">Agenda</h2>
        <div className=" lg:mx-8">
          <p className="text-lg text-justify  max-w-[310px] text-black ">
            - EN CONSTRUCTION - Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis
            odit. At dolor labore id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae
            eligendi vel nesciunt galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde
            quo doloremque necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id
            voluptate rerum. Ut obcaecati ducimus sit maxime dolores et atque iure.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Agenda;
