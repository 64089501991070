import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState, useEffect } from "react";
import CustomCloseIcon from "../CustomCloseIcon";

function Box({ tab, cssGallery }) {
  const [open, setOpen] = useState(false);
  const [indexState, setIndexState] = useState(0);
  const [closeOnBackdropClick] = useState(true);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <div
        className={`grid ${cssGallery} z-0 transition-opacity ease-in-out duration-[1000ms] delay-0  ${
          loaded ? "opacity-100" : "opacity-0"
        }`}
      >
        {tab.map((image, index) => {
          return (
            <div key={`${image.src}-${index}`} className="group lien-conteneur-photo relative">
              <img
                src={image.src}
                alt={image.src}
                className="relative -z-50 group-hover:scale-110 group-hover:z-50 transition-all ease-in-out duration-500 w-full h-full object-cover"
              ></img>
              <div className="absolute flex top-0 bottom-0 left-0 right-0 ">
                <button
                  className="z-50 w-full"
                  onClick={() => {
                    setIndexState(index);
                    setOpen(true);
                  }}
                ></button>
              </div>
            </div>
          );
        })}
      </div>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={tab}
        index={indexState}
        styles={{ root: { "--yarl__color_backdrop": "rgba(20, 8, 0, 0.8)" } }}
        render={{ iconClose: () => <CustomCloseIcon /> }}
        controller={{ closeOnBackdropClick }}
      />
    </>
  );
}

export default Box;
