import { Link } from "react-router-dom";
import { useState } from "react";
import BackgroundComponent from "../../components/BackgroundComponent";
function Creations() {
  const propertyBackground = "bg-creations";
  const cssBackground = ` lg:bg-center lg:bg-contain `;
  return (
    <main className="flex-grow relative">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />

      <section className=" items-center text-2xl align-center text-white flex flex-col justify-center  max-w-fit min-h-[80vh] m-auto ">
        <h2 className="lg:hidden text-2xl my-6">Créations</h2>

        <Link className="w-fit py-3 hover:scale-125 transition-all duration-500" to="/video-club">
          Video Club
        </Link>
        <br />
        <Link
          className="w-fit py-3  hover:scale-125  transition-all duration-500"
          to="/jamais-la-fin"
        >
          Jamais La Fin
        </Link>
        <br />
        <Link className="w-fit py-3  hover:scale-125  transition-all duration-500" to="/insitu">
          Perfomances In Situ
        </Link>
        <br />
      </section>
    </main>
  );
}

export default Creations;
