import BackgroundComponent from "../../components/BackgroundComponent";
import image1 from "../../assets/Apropos/apropos.webp";
import { useState, useEffect } from "react";

function Apropos() {
  const PropertyBackground = "";
  const cssBackground = ` -z-20  bg-center bg-cover`;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <main className="flex-grow relative">
      <img
        src={image1}
        alt="apropos-image"
        className={`max-w-[100%] mx-auto  transition-opacity ease-in-out duration-[4000ms] ${
          loaded ? "opacity-100" : "opacity-0"
        }`}
      />
      <BackgroundComponent PropertyBackground={PropertyBackground} cssBackground={cssBackground} />
      <section className=" justify-center items-center lg:text-center flex lg:flex-row lg:mt-8 mt-16 mb-8 flex-col lg:max-w-[50%] mx-auto">
        <h2 className="lg:hidden text-2xl my-8">A Propos</h2>
        <div className="">
          <p className=" text-lg  text-justify   m-auto   ">
            Angelica Ardiot et Naomi Charlot commencent à travailler ensemble en 2019 puis créent
            officiellement leur compagnie NANA MOVEMENT en 2020. Cette même année, elles nouent un
            partenariat fort avec le RING- scène périphérique, lieu de soutien aux artistes et à la
            création contemporaine situé dans le quartier des Septs Deniers à Toulouse et co-dirigé
            par Samuel Mathieu (chorégraphe) et Christophe Bergon (metteur en scène). Un partenariat
            qui prendra petit à petit la forme d’une production déléguée jusqu’en juin 2024.
          </p>{" "}
          <br />
          <p className=" text-lg  text-justify   m-auto   ">
            En février 2021, la première de leur duo VIDEO CLUB, leur premier projet, a lieu au
            RING-scène périphérique dans le cadre du Neuf Neuf Festival. Depuis, le duo tourne en
            région et tant à s’exporter.
          </p>{" "}
          <br />
          <p className=" text-lg  text-justify   m-auto   ">
            À la suite de ça, Angelica et Naomi transmettent la pièce à deux interprètes hommes dans
            le désir de pouvoir entamer des recherches autour d’un quatuor. C’est à ce moment que
            commence le travail autour de Jamais la fin (création 2023).
          </p>{" "}
          <br />
          <p className=" text-lg  text-justify   m-auto   ">
            Simultanément, depuis janvier 2021, elles mettent en place au RING-Scène Périphérique
            leur concept des NANA JAM. Cette proposition exprime la volonté de la compagnie de créer
            du lien et de la rencontre autour de la matière chorégraphique.
          </p>{" "}
          <br />
          <p className=" text-lg  text-justify   m-auto   ">
            De manière générale, NANA MOVEMENT s’inscrit dans un processus de création qui travaille
            l’écriture chorégraphique à travers d’autres médiums tels que la photo et la vidéo. La
            compagnie met en avant une direction artistique très épurée et minimaliste à
            l’esthétique techno tant dans le rapport à la musique qu’aux choix portés à
            l’environnement scénique (costumes, décors, lumières). Leurs premiers projets VIDEO CLUB
            et Jamais la Fin en attestent et expriment une volonté d’explorer la performance
            physique et l’épuisement des corps. De plus, le travail de partition et le rapport à
            l’espace est le point commun à toutes les formes de créations de la compagnie. En effet,
            nous retrouvons à chaque fois une écriture stricte et mathématique, qui vient
            matérialiser et investir une dimension spatiale donnée. Tant quand il s’agit d’une
            création au plateau que dans le format de performances in situ que les deux chorégraphes
            commencent à développer.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Apropos;
