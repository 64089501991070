import BackgroundComponent from "../../components/BackgroundComponent";

function Ateliers() {
  const PropertyBackground = "bg-cremaster5";
  const cssBackground = ` lg:bg-[-10%_top] lg:bg-[length:900px] lg:!top-[100px] bg-cover bg-center`;
  return (
    <main className="flex-grow relative">
      <BackgroundComponent PropertyBackground={PropertyBackground} cssBackground={cssBackground} />
      <section className="z-0 flex flex-col   justify-center lg:justify-end lg:mt-0 mt-36 max-w-[80%] mx-auto lg:items-end ">
        <h2 className="lg:hidden text-2xl my-8">Ateliers</h2>
        <div className="lg:my-[10%] lg:max-w-96 lg:mr-32">
          <p className="text-lg text-justify ">
            - EN CONSTRUCTION - Lorem ipsum dolor sit amet. Est nisi nisi sit natus quia sit nobis
            odit. At dolor labore id culpa Quis sed velit reiciendis ut dolore molestiae ex vitae
            eligendi vel nesciunt galisum qui adipisci dolore. Ut repellat omnis aut deserunt unde
            quo doloremque necessitatibus eos omnis provident qui laborum nulla eum sunt quasi id
            voluptate rerum. Ut obcaecati ducimus sit maxime dolores et atque iure.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Ateliers;
