import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import EnterPage from "./pages/EnterPage";
import Contact from "./pages/Contact";
import Agenda from "./pages/Agenda";
import NanaJam from "./pages/NanaJam";
import Ateliers from "./pages/Ateliers";
import Creations from "./pages/Creations";
import JamaisLaFin from "./pages/Creations/JamaisLaFin";
import VideoClub from "./pages/Creations/VideoClub";
import InSitu from "./pages/Creations/InSitu";
import Apropos from "./pages/Apropos";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { BackgroundProvider } from "./utils/Context";

import reportWebVitals from "./reportWebVitals";
import BackgroundComponent from "./components/BackgroundComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <BackgroundProvider>
        <Header />
        <Routes>
          <Route path="/" index element={<EnterPage />} />
          <Route path="/about" element={<Apropos />} />
          <Route path="/creations/" element={<Creations />} />

          <Route path="/jamais-la-fin" element={<JamaisLaFin />} />
          <Route path="/video-club" element={<VideoClub />} />
          <Route path="/insitu" element={<InSitu />} />

          <Route path="/nanajam" element={<NanaJam />} />
          <Route path="/ateliers" element={<Ateliers />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <Footer />
      </BackgroundProvider>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
