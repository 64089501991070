import BackgroundComponent from "../../../components/BackgroundComponent";
import { useState, useEffect } from "react";
import Box from "../../../components/Box";
import image1 from "../../../assets/Creations/JamaisLaFin/jl1.webp";
import image2 from "../../../assets/Creations/JamaisLaFin/jl2.webp";
import image3 from "../../../assets/Creations/JamaisLaFin/jl3.webp";
import image4 from "../../../assets/Creations/JamaisLaFin/jl4.webp";
import image5 from "../../../assets/Creations/JamaisLaFin/jl5.webp";
import image6 from "../../../assets/Creations/JamaisLaFin/jl6.webp";
import image7 from "../../../assets/Creations/JamaisLaFin/jl7.webp";
import image8 from "../../../assets/Creations/JamaisLaFin/jl8.webp";
import image9 from "../../../assets/Creations/JamaisLaFin/jl9.webp";

function JamaisLaFin() {
  const propertyBackground = "bg-jamaislafin";
  const cssBackground = ` lg:bg-[right_25%]  bg-cover  bg-center `;
  const [loaded, setLoaded] = useState(false);

  const tab = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9 },
  ];
  const cssGallery = "lg:grid-cols-[1fr_2fr_1fr] gap-3 mt-4 grid-cols-[1fr]";

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <main className="flex-grow relative ">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <section className=" lg:justify-around align-center text-[#000000] lg:text-white flex flex-col lg:max-w-[80%] mx-auto mt-32">
        <div className="textNgallery flex flex-row">
          <div className="w-1/2">
            <h1 className="font-['Centrion'] mb-0 pb-0  text-2xl font-bold lg:font-normal lg:text-white text-white [text-shadow:1px_1px_2px_black] lg:[text-shadow:none]">
              JAMAIS LA FIN
            </h1>
            <p className=" mt-0 pt-0 lg:text-white text-white">création 2023</p>
            <br />
            <p className="text-lg text-left mr-4 lg:text-white text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              “Continuité chorégraphique des deux précédents duos de la compagnie, Jamais la fin
              déploie sa recherche dans la confrontation des duos féminin et masculin. Créer une
              nouvelle écriture à partir d’un matériel corporel existant, mélanger nos pas jusqu’à
              les confondre, les oublier et les recréer, voilà ce qui nous intéresse. Cette notion
              de continuum artistique nous a donné l’envie de questionner le concept de finitude,
              caractère de ce qui est fini. Comment par le biais du corps pouvons-nous repousser nos
              limites physiques grâce à une cadence soutenue du début à la fin ? C’est ce que nous
              mettons en jeu sur le plateau avec cette écriture très bornée où nous cherchons à
              recommencer sans cesse. Ne voulant pas accepter notre propre fin, malgré le caractère
              évident qu’il y en aura une, ce quatuor est une exploration de « la fin » où
              l’acceptation doit se faire, c’est un aller retour entre contrôle et abandon. <br />
              <br />
              Il a été évident pour nous d’inclure dans notre création la notion de fête. À nos yeux
              ces espaces de fêtes sont des endroits où tout est possible dans un cadre safe, des
              endroits de lâcher prise et de connexion à soi et à l’autre. Ce sont des espaces hors
              du temps, où l’exploration des sens est omniprésente. C’est un exutoire physique et
              mental qui nous fait nous sentir vivant·es. La rencontre des corps y est omniprésente,
              et très vite l’individu devient groupé. Jongler entre le singulier et le collectif,
              chercher le déploiement d’un corps et d’un langage commun malgré l’exigence du cadre
              donné. Ce format quatuor c’est aussi avoir plusieurs corps différents, de sorte que la
              multiplicité des registres d’interprétations soit évidente et ressorte au milieu de
              cet ensemble..” <br />
              <br />
              La découverte du poème «Dancefloor politique» de Hewan Aman extrait de l’ouvrage
              <em>
                Au-delà du club, recherche poétique féministe et collaborative pour ré-imaginer les
                pratiques festives des scènes électroniques
              </em>
              , a été une source d’inspiration et un appui très fort tout au long du processus de
              création.
            </p>
            <div
              className={`max-w-[700px] py-8 transition-opacity ease-in-out duration-[4000ms] ${
                loaded ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="relative overflow-hidden w-full pt-[56.25%]">
                <iframe
                  title="jamaisLaFinTeaser"
                  className={`absolute top-0 left-0 bottom-0 right-0 w-full h-full `}
                  id="ytplayer"
                  type="text/html"
                  src="https://www.youtube.com/embed/poObfnBtzx8?autoplay=1&origin=http://example.com"
                  allowFullscreen
                ></iframe>
              </div>
            </div>
            <br />
          </div>
          <div className="z-0  flex-grow w-1/2">
            <Box tab={tab} cssGallery={cssGallery} />
          </div>
        </div>
        <div className="flex flex-row ">
          <div>
            <h2>Distribution</h2>
            <p className=" text-left mr-4  text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              Chorégraphes Angelica Ardiot et Naomi Charlot <br /> Interprètes Angelica Ardiot,
              Naomi Charlot, Thomas Queyrens et en cours <br />
              Lumières Cloé Freneix Musiques <br />
              Durée 40 minutes
              <br />
            </p>
          </div>
          <div>
            <h2>Partenaires</h2>
            <p className=" text-left mr-4  text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              Production déléguée RING - Scène Périphérique <br />
              Coproduction CIAM La Fabrique, L’Étoile du Nord <br />
              Accueil en résidence CIAM La Fabrique / L’Escale Tournefeuille / CCN Ballet du Nord
              Roubaix <br />
              Aide à la création du Conseil Départemental de la Haute Garonne <br />
              La compagnie est «Partenaire de l’Escale - Ville de Tournefeuille» <br />
              Elle a été repérée par l’ONDA pour son premier projet et a été soutenue par Occitanie
              en Scène, la Caisse des Dépôts et le Conseil Départemental de la Haute Garonne.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-row">
          <div>
            <h2>À venir</h2>
            <p className=" text-left mr-4  text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              2024 <br /> 4.06 : RING - scène périphérique, Toulouse (31) <br />
              6.03 : Auditorium Marthe Condat, Université Paul Sabatier, Toulouse (31) <br />
              <br />
            </p>
          </div>
          <div>
            <h2>Précédemment</h2>
            <p className=" text-left mr-4  text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              2023 <br /> 7.06 : Création, L’Escale Tournefeuille, Tournefeuille (31) <br />
              13 et 14.01 : maquette, Festival Open Space, L’Étoile du Nord, Paris (75)
              <br />
              <br />
            </p>
          </div>
          <div>
            <h2>Calendrier de résidence </h2>
            <p className=" text-left mr-4  text-white [text-shadow:2px_1px_2px_black] lg:[text-shadow:none]">
              2022 <br /> 11 au 15.07 : RING - Scène périphérique,Toulouse (31) <br />
              5 au 9.09 : CIAM La Fabrique, Toulouse (31) <br />
              10 au 14.10 : L’Escale Tournefeuille, Tournefeuille (31) <br />
              31.10 au 04.11 : L’Escale Tournefeuille, Tournefeuille (31) <br />
              2023 02 au 06.01 : Ballet du Nord, CCN de Roubaix, Roubaix (59) <br />
              04 au 05.05 : RING-Scène périphérique, Toulouse (31)
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JamaisLaFin;
