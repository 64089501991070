import { createContext, useState } from "react";

export const BackgroundContext = createContext();

export const BackgroundProvider = ({ children }) => {
  const [background, setBackground] = useState("");
  const [globalState, setGlobalState] = useState(false);

  const toggleBackground = (nameOfBackground, cssBackground) => {
    setGlobalState(false);
    setTimeout(() => {
      setBackground(nameOfBackground + cssBackground);
      setGlobalState(true);
    }, 500);
  };

  return (
    <BackgroundContext.Provider value={{ background, toggleBackground, globalState }}>
      {children}
    </BackgroundContext.Provider>
  );
};
