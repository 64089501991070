import BackgroundComponent from "../../../components/BackgroundComponent";
import { useState, useEffect } from "react";
import Box from "../../../components/Box";
import hero from "../../../assets/Creations/VideoClub/videoclub2-heroes.webp";
import image1 from "../../../assets/Creations/VideoClub/vc_1.webp";
import image2 from "../../../assets/Creations/VideoClub/vc_2.webp";
import image3 from "../../../assets/Creations/VideoClub/vc_3.webp";
import image4 from "../../../assets/Creations/VideoClub/vc_4.webp";
import image5 from "../../../assets/Creations/VideoClub/vc_5.webp";
import image6 from "../../../assets/Creations/VideoClub/vc_6.webp";

function VideoClub() {
  const [loaded, setLoaded] = useState(false);
  const propertyBackground = "";
  const cssBackground = `  lg:bg-cover bg-cover bg-center `;
  const tab = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
  ];
  const cssGallery = "lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-3  grid-cols-[1fr]";

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <main className="flex-grow relative ">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <img
        src={hero}
        alt="apropos-image"
        className={`max-w-[100%] mx-auto  transition-opacity ease-in-out duration-[4000ms] ${
          loaded ? "opacity-100" : "opacity-0"
        }`}
      />
      <section className="lg:justify-around align-center flex flex-col   text-[#0000ff] my-8">
        <div className="textP lg:max-w-[50%] mx-auto flex flex-col">
          <h1 className="font-['Centrion']  mb-0 pb-0  text-3xl font-bold lg:font-normal ">
            VIDEO CLUB
          </h1>
          <p className=" mt-0 pt-0 text-2xl">création 2021</p>
          <br />
          <p className="text-lg text-left   my-4 ">
            “Un univers électronique qui fait appel à l’imaginaire, au désir et à l’évasion. Video
            Club c’est deux corps qui cherchent à transcender l’espace scénique, en explorer la
            spatialité et la dimension jusqu’à l’épuisement, laissant finalement place au souffle.
            Une écriture qui met en avant la synchronisation et la gémellité des interprètes. Cette
            performance est un appel à se connecter à soi et à l’autre à travers l’exploration du
            lâcher prise.” <br />
            <br />
            Tout en gardant une esthétique minimaliste, nous souhaitons créer une atmosphère sombre,
            une ambiance bruts et industriels. En fond de scène se trouve une boîte semi-ouverte
            recouverte d’un tissu bleu électrique où se reflète la lumière des néons qui éclairent
            la piste de danse. Elle représente quelque chose d’intime, un endroit clos que nous
            souhaitons petit à petit ouvrir. Nous le voulons comme un espace safe et ouvert à tous
            et à toutes, une invitation à la rencontre, au-delà des genres, des origines, des
            milieux sociaux et des générations. <br />
            <br />
            Aussi, nous accordons une place importance à l’espace sonore, entre techno et silence :
            une musique par essence répétitive et entraînante en alternance aux espaces de silence
            qui rendent perceptible les souffles et les bruits des corps. Cet univers électronique
            où des images de « clubbing » apparaissent fait appel à l’imaginaire, au désir et à
            l’évasion, amenant les corps à un état d’épuisement et de frénésie : une exploration du
            lâché prise.
          </p>
          <div
            className={` py-8 transition-opacity ease-in-out duration-[4000ms] ${
              loaded ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="relative overflow-hidden w-full pt-[56.25%]">
              <iframe
                title="videoClubTeaser"
                className={`absolute top-0 left-0 bottom-0 right-0 w-full h-full `}
                id="ytplayer"
                type="text/html"
                src="https://www.youtube.com/embed/F9DquLjsVSc?autoplay=1&origin=http://example.com"
                allowFullscreen
              ></iframe>
            </div>
          </div>
          <br />
        </div>
        <div className="z-0  flex-grow lg:max-w-[90%] mx-auto">
          <Box tab={tab} cssGallery={cssGallery} />
        </div>
        <div className="flex flex-col lg:max-w-[50%] mx-auto my-4">
          <div>
            <h2 className="text-5xl mb-3">☞ Distribution</h2>
            <p className=" text-left    ">
              Chorégraphes Angelica Ardiot et Naomi Charlot <br />
              Interprètes Angelica Ardiot et Naomi Charlot en alternance Thomas Queyrens <br />{" "}
              Lumières Cloé Freneix <br /> Création scénographie Claire Péré <br /> Musique Pantha
              du Prince - Behind the stars / Casual Gabberz - IDF interlude V / Evil Grimace - Pour
              mes gens <br /> Durée 25 minutes <br />
              <br />
            </p>
          </div>
          <div>
            <h2 className="text-5xl mb-3">☞ Partenaires</h2>
            <p className=" text-left    ">
              Production déléguée RING - Scène Périphérique <br /> Coproduction NEUFNEUF Plateforme
              Cie Samuel Mathieu <br /> Soutien Aide à la création et Aide à la diffusion du Conseil
              Départemental de la Haute-Garonne / Aide à la mobilité Occitanie en Scène / Avec le
              soutien du groupe Caisses des Dépôts /Avec le soutien de La Boîte à Pandore (31).{" "}
              <br />
              Repéré par l’ONDA, la compagnie est «Partenaire de l’Escale - Ville de Tournfeuille»
              (31).
              <br />
              <br />
            </p>
          </div>
          <div>
            <h2 className="text-5xl mb-3">☞ La presse en parle</h2>
            <p className=" text-left    ">
              https://avoiretadanser.fr/joana-schweizer-et-nana-movement/
              <br />
              <br />
            </p>
          </div>
          <div>
            <h2 className="text-5xl mb-3">☞ Précédemment</h2>
            <p className=" text-left    ">
              <span className="font-bold">2023</span> <br />
              21.10 Festival Mouvements, St-Girons (09) <br />
              16.10 Fête de la science, Université Paul Sabatier, Toulouse (31) <br />
              27.05 Festival «Alors on danse !», Adda de l’Aveyron (12) <br />
              <span className="font-bold">2022</span> <br />
              08.12 Dispositif des ADDA, Espace Roguet, Toulouse (31) <br />
              04 et 05.10 Avis de Turbulence - Étoile du Nord - Paris (75) <br />
              19.09 Festival CIAM La Fabrique - Toulouse (31) <br />
              18.09 Festival Les Frissonnantes - Cintegabelle (31) <br />
              03.04 Festival Arrêt Danse - Station A - Rodez (12) <br />
              <span className="font-bold">2021</span> <br />
              11 et 20.11 NeufNeuf Festival, Cie Samuel Mathieu - Cugnaux (31) <br />
              28.08 Festival 31 Notes d’été - Château de Laréole, (31) <br />
              27.08 ATOM festival - Castelnaudary (12) <br />
              12 et 13.02 CRÉATION - Flash NeufNeuf, Cie Samuel Mathieu - RING - Scène Périphérique
              - Toulouse (31) <br />
              <span className="font-bold">2019</span> <br />
              La Nuit Européenne des Musées aux Abattoirs, Toulouse (31) Festival d’Art d’Art,
              Verdun sur Garonne (82) <br />
              Festival La Vadrouille, Beaumont sur Lèze (31) <br />
              Rencontres Mouvementées - La Boîte à Pandore, Toulouse (31) <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default VideoClub;
