import { useState } from "react";
import { createPortal } from "react-dom";
import Home from "../Home";
import enteredIcon from "../../assets/EnteredIcon.png";

function EnterPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [stateTransition, setStatetransition] = useState(false);
  function timer() {
    setIsOpen(true);
    setTimeout(() => {
      setStatetransition(true);
      console.log("fin");
    }, 1000);
  }

  return (
    <>
      {createPortal(
        <>
          <div
            className={`entr  transition-opacity ease-in-out duration-[1000ms] bg-white fixed top-0 left-0 right-0 bottom-0 flex w-[100%] h-[100vh] m-0 ${
              isOpen ? "opacity-0 " : " opacity-100  "
            } ${stateTransition ? "-z-50" : "z-10"} `}
          >
            <div
              className={` fixed top-0 left-0 right-0 bottom-0 flex w-[100%]  m-0   
         justify-center items-center  bg-welcome bg-cover  lg:bg-contain bg-no-repeat lg:bg-right
        `}
            >
              <div
                className={`${
                  isOpen ? "hidden" : "flex items-center justify-center flex-col"
                }   z-50 `}
              >
                <img
                  src={enteredIcon}
                  alt="enterde_Icon"
                  className="lg:max-w-96 max-w-72 cursor-pointer"
                  onClick={() => timer()}
                />
                {/* <button className="my-16 text-[#9c33d9] text-2xl" onClick={() => timer()}>
                  Entrer
                </button> */}
              </div>
            </div>
          </div>
        </>,
        document.body,
      )}
      <Home />
    </>
  );
}

export default EnterPage;
