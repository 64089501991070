function Footer() {
  return (
    <footer className="group fixed bottom-0 w-[5%] hover:w-full lg:block hidden">
      <div className="triggerfooter whitespace-nowrap  lg:text-xs py-3 text-justify  group-hover:invisible ">
        Retrouvez-nous
      </div>

      <nav className=" bg-white w-[100vw] invisible h-0 group-hover:h-[60px] group-hover:visible group-hover:transition linear duration-[800ms] delay-300  px-[50px] group-hover:py-4">
        <div className="lg:text-xl text-base lg:mr-10 flex flex-row">
          <a
            href="https://www.facebook.com"
            className="ml-16 px-4 mx-2 border-t-[0.2px] border-gray-500 hover:opacity-50 whitespace-nowrap"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com"
            className="ml-8 mx-2 px-4 border-t-[0.2px] border-gray-500 hover:opacity-50 whitespace-nowrap"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com"
            className="ml-8 mx-2 px-4 border-t-[0.2px] border-gray-500 hover:opacity-50 whitespace-nowrap"
          >
            Youtube
          </a>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
